<!--
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-12 12:27:48
 * @FilePath: \hh\PC\h6\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app" class="app">
    <NavBar />
    <template v-if="$route.meta.isNavBar != 2">
      <NavBar v-if="whetherNavBar" v-show="this.$store.state.isNavBar" />
      <div class="design_scheme_content_box" v-if="!whetherNavBar">
        <el-page-header class="design_scheme_content" @back="handleBack" :content="titleContent">
        </el-page-header>
      </div>
    </template>
    <!-- keep-alive 表示页面不会被销毁，即保持页面状态 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <el-backtop :bottom="100">
      <div style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0px 4px 8px rgb(5  56  92 / 8%);
        text-align: center;
        line-height: 40px;
        color: #1d1d1f;
        font-weight:600;
        border-radius:5px;
      }">UP</div>
    </el-backtop>

    <div @touchmove.prevent v-if="$store.state.loading" v-loading="$store.state.loading"
      style="position: fixed;top: 44px;left: 0;min-width: 100vw;min-height: 100vh;z-index: 998;"></div>
  </div>
</template>

<script>
import NavBar from './layout/components/navbar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
  },
  data() {
    return {
      whetherNavBar: true,
    }
  },
  created() {
    this.whetherNavBar = this.$route.meta.isNavBar ? true : false;
  },
  mounted(){
  },
  computed: {
    titleContent() {
      return this.$store.state.content
    },
  },
  watch: {
    $route(to, from) {
      this.whetherNavBar = to.meta.isNavBar ? true : false;
    }
  },
  methods:{
    handleBack(){
      console.log('111',this.$route);
      if(!this.$route.meta.is_not_fallback){
         this.$router.back();
      }else{
        this.$router.push('/personal');
      }
    }
  }
}
</script>

<style lang="less">
body{
  margin: 0;
  padding: 0;
  background: #F5F5F7;
  width: 1200px;
  margin: 0 auto;
  // height: 100%;
  // //弹框
  // /deep/.el-message-box__wrapper {
  //   .el-message-box {
  //     .el-message-box__header,
  //     .el-message-box__content {
  //       text-align: center !important;
  //     }
  //   }
  // }
}

.box-card-link{
    text-decoration:none;
    color: #303133;
}
.pagination{
    text-align: center;
    margin: 20px auto 10px;
}
/* 放大镜 */ 
.mouse-cover-canvas {
  left: 810px !important;
  top: 152px !important;
  border-radius: 8px;
  z-index: 888 !important;
  // width: 550px !important;
  // height: 550px !important;
}
.magnifier-box {
  img {
    height: 100% !important;
    width: 100% !important;
  }
  .mouse-cover{
    cursor: auto;
    pointer-events: none;
    // width: 100px !important;
    // height: 100px !important;
  }
}

.app {
  background: #F5F5F7;
  width: 100%;
  height: 100%;
  .design_scheme_content_box {

    width: 100%;
    height: 62px;
    background: #FFFFFF;
    color: rgba(0, 0, 0, .7);
    box-shadow: @boxShadowBlack;
    position: fixed;
    // padding-left: 360px;
    top: 44px;
    left: 0;
    z-index: 99;
    box-sizing: border-box;
  }

  .design_scheme_content {
    width: @width1200;
    margin-left: 50%;
    transform: translateX(-50%);
    height: 62px;
    line-height: 62px;

    .el-page-header__title,
    .el-icon-back {
      font-size: 16px;
    }

    .el-page-header__content {
      font-size: 20px;
      color: rgba(0, 0, 0, .5);
    }

    .el-page-header__left {
      font-size: 16px;
      color: rgba(0, 0, 0, .5);
    }

    .el-page-header__left::after {
      width: 1px;
      height: 24px;
      background-color: rgba(0, 0, 0, .12);
    }
  }

}



.myClass {
  width: 1014px;
  border-radius: 10px !important;
  height: 305px;
  // position: relative !important;
  // top: 212px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 99 !important;

  .el-scrollbar {
    width: 340px;

    .el-cascader-menu__wrap {
      height: 303px;
    }
  }
}


#_magnifier_layer {
  left: 800px !important;
  border-radius: 8px;
}


.input_text::-webkit-outer-spin-button,
.input_text::-webkit-inner-spin-button {
    appearance: none !important;
}

.input_text[type='number'] {
    appearance: textfield;
}
</style>
