/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-04 11:12:00
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import store from '../../store'
import request from '../request'
const http = {
    mobile: 'pc',
    wisdom: 'wisdom',
}
const apis = {
    //热门搜索
    hotSearch(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=search&op=hot_history`, params)
    },
    //最近搜索
    latelySearch(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_index&op=member_history`, params)
    },
    //添加搜索记录
    addSearch(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_index&op=member_history_save`, params)
    },
    //删除搜索记录
    delSearch(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_index&op=member_history_del`, params)
    },
    //搜索匹配项
    aotocomplete(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=search&op=aotocomplete`, params)
    },
}
export default apis;